import { SvgIconProps } from '@mui/material';
import { Area, Room } from '@portals/core-immobilien/src/icons';
import { MainfactCategory } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { find, get } from 'lodash-es';
import { FC } from 'react';

export const getAllMainfactCategoryIcons = (): { category: MainfactCategory; icon: FC<SvgIconProps> }[] => {
  return [
    {
      category: MainfactCategory.ROOMS,
      icon: Room,
    },
    {
      category: MainfactCategory.AREA,
      icon: Area,
    },
  ];
};

export const getMainfactCategoryIcon = (category: MainfactCategory): FC<SvgIconProps> => {
  const mainfactItem = find(getAllMainfactCategoryIcons(), { category });
  return get(mainfactItem, 'icon', '');
};
