import { Box, Grid, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import { CheckCircleOutline } from '@portals/icons';
import { map } from 'lodash-es';
import React from 'react';

interface Value {
  value: string;
}

interface Props {
  data: Value[];
  iconColor?: 'green' | 'black';
}

export const ContentCheckedList: React.FunctionComponent<Props> = ({ data, iconColor = 'green' }: Props) => {
  const theme = useTheme<Theme>();
  const grid = data.length > 5 ? 6 : 12;
  return (
    <Grid container>
      {map(data, (labelValuePair) => {
        return (
          <Grid
            item
            xs={12}
            md={grid}
            lg={grid}
            display="flex"
            alignItems="center"
            pb={3}
            key={labelValuePair.value}
            sx={{
              '@media print': {
                flexBasis: '50%',
                maxWidth: '50%',
                '.checked-icon-container svg': { color: theme.palette.text.primary },
              },
            }}
          >
            <Box pr={5} display="flex" alignItems="center">
              <CheckCircleOutline htmlColor={iconColor} />
            </Box>
            <Typography variant="button" sx={{ '@media print': { fontSize: '13px' } }}>
              {labelValuePair.value}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

ContentCheckedList.displayName = 'ContentCheckedList';
