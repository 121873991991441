import { ButtonProps } from '@mui/material/Button';
import { FilterPill } from '@portals/immobilien/src/components/HorizontalSwipeBar/FilterPill';
import React from 'react';

import { Floorplan, ImageIcon, ThreeSixty, Video } from '../../../icons';

export enum GalleryFilterType {
  IMAGE = 'image',
  VIDEO = 'video',
  THREE_SIXTY = '360tour',
  FLOORPLAN = 'floorplan',
}

export interface GalleryButtonProps {
  label: string | null;
  type: GalleryFilterType;
  onClick: ButtonProps['onClick'];
}

const iconMap = {
  [GalleryFilterType.IMAGE]: ImageIcon,
  [GalleryFilterType.VIDEO]: Video,
  [GalleryFilterType.THREE_SIXTY]: ThreeSixty,
  [GalleryFilterType.FLOORPLAN]: Floorplan,
};

export const GalleryButton = ({ label, type, onClick }: GalleryButtonProps): React.ReactElement => {
  const Icon = iconMap[type];

  return (
    <FilterPill
      className="estate-filter-pill"
      avatar={
        Icon && (
          <Icon
            sx={{
              height: { lg: 24 },
              width: { lg: 24 },
            }}
          />
        )
      }
      label={label}
      onClick={onClick}
    />
  );
};

GalleryButton.displayName = 'GalleryButton';
