import { ConfigProvider } from '../general/Config';
import { IF6Config } from './IF6Config';

const ESTATE_ID_PLACEHOLDER = '{id}';

export const getEstateDetailsPath = (id: string): string => {
  const path = (ConfigProvider.getConfig() as IF6Config).get('ESTATE_DETAIL_PAGE');
  const estateId = id;

  if (typeof path === 'undefined' || path === '') {
    return '';
  }

  if (path.indexOf(ESTATE_ID_PLACEHOLDER) !== -1) {
    return path.replace(ESTATE_ID_PLACEHOLDER, estateId);
  }

  // There is an empty eid GET Param
  if (path.match(/(?<=[?|&])eid=(^&|$)/)) {
    return path.replace(/(?<=[?|&])eid=/, `eid=${estateId}`);
  }

  // There is a prefilled eid GET Param
  if (path.match(/(?<=[?|&])eid=/)) {
    return path;
  }

  // There is no eid GET Param but other params are present
  if (path.match(/\?/)) {
    return path.concat(`&eid=${estateId}`);
  }

  // There are no GET params
  return path.concat(`?eid=${estateId}`);
};
