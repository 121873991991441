import styled from '@emotion/styled';
import { Box, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { theme } from '@portals/core/src/themes/sde/main';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { ThreeSixtyBig } from '../../../icons';

export type GalleryCarouselItemType = {
  url: string;
  description: string;
};

export type GalleryCarouselThreeSixtyItemType = {
  url: string;
  description: string;
};

const Img = styled.img({
  maxWidth: '100%',
  objectFit: 'contain',
  maxHeight: 'calc(100vh - 230px)',
  [theme.breakpoints.up('lg')]: {
    maxHeight: 'calc(100vh - 250px)',
  },
});

export const GalleryCarouselItem = ({ url, description }: GalleryCarouselItemType): React.ReactElement => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        padding: { xs: theme.spacing(0), sm: theme.spacing(0, 11) },
      }}
    >
      <Img src={url} alt={description} />
    </Box>
  );
};

GalleryCarouselItem.displayName = 'GalleryCarouselItem';

export const GalleryCarouselThreeSixtyItem = ({ url }: GalleryCarouselThreeSixtyItemType): React.ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const button360Spacing = theme.spacing(9);
  const galleryImageHeight = {
    xs: `calc(100vh - 230px - ${button360Spacing})`,
    lg: `calc(100vh - 250px - ${button360Spacing})`,
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <ThreeSixtyBig
        htmlColor={theme.palette.text.primary}
        sx={{
          width: galleryImageHeight,
          height: galleryImageHeight,
          maxHeight: '180px',
        }}
      />
      <Button href={url} component="a" target="_blank">
        {t('gallery.threeSixtyViewOpen')}
      </Button>
    </Box>
  );
};

GalleryCarouselThreeSixtyItem.displayName = 'GalleryCarouselThreeSixtyItem';
