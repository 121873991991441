import { GalleryImage } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { head } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';

import { GalleryFilterType } from '../GalleryButton/GalleryButton';
import {
  GalleryCarouselItem,
  GalleryCarouselItemType,
  GalleryCarouselThreeSixtyItem,
  GalleryCarouselThreeSixtyItemType,
} from '../GalleryCarousel/GalleryCarouselItem';
import { GalleryModal } from '../GalleryModal/GalleryModal';

export interface Props {
  images: Array<GalleryImage>;
  galleryOpen: boolean;
  onClose: () => void;
  currentImagePosition: number | null;
}

export const Gallery = ({ images, galleryOpen, onClose, currentImagePosition }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [itemsToRender, setItemsToRender] = useState<GalleryCarouselItemType[] | GalleryCarouselThreeSixtyItemType[]>(
    []
  );
  const handleGalleryClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const getImageBySize = (image: GalleryImage, imageSize: string) => {
    const originalImage = image.resources.filter((resource) => resource.size === imageSize);
    return head(originalImage)?.url;
  };

  const getGalleryItems = useCallback(
    (filteredImages) => {
      const generateGalleryCarouselItems = (imgUrl, description) => {
        return <GalleryCarouselItem description={description} url={imgUrl} key={`${imgUrl}__${description}`} />;
      };

      const generateGalleryCarouselThreeSixtyItems = (threeSixtyUrl) => {
        return (
          <GalleryCarouselThreeSixtyItem
            url={threeSixtyUrl}
            description={t('gallery.threeSixty')}
            key={`${threeSixtyUrl}`}
          />
        );
      };

      return filteredImages.map((image) => {
        switch (image.type) {
          case GalleryFilterType.FLOORPLAN:
            return generateGalleryCarouselItems(getImageBySize(image, 'xl'), image.description);
          case GalleryFilterType.THREE_SIXTY:
            return generateGalleryCarouselThreeSixtyItems(head(image.resources)?.url);
          default:
            return generateGalleryCarouselItems(getImageBySize(image, 'xl'), image.description);
        }
      });
    },
    [t]
  );

  useEffect(() => {
    setItemsToRender(getGalleryItems(images));
  }, [getGalleryItems, images]);

  return (
    <GalleryModal
      currentImagePosition={currentImagePosition}
      open={galleryOpen}
      onClose={handleGalleryClose}
      items={itemsToRender}
    />
  );
};

Gallery.displayName = 'Gallery';
