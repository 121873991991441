import { Box } from '@mui/material';
import { useAspectRatio } from '@portals/core/src/hooks/useAspectRatio';
import React from 'react';

import { generateImageUrls } from '../../../utils/imageUtils';

interface MediaProps {
  media: { alternativeText: string; url: string };
  aspectWidth: number;
  aspectHeight: number;
}

export const AspectMedia: React.FunctionComponent<MediaProps> = ({ media, aspectWidth, aspectHeight }: MediaProps) => {
  const { ref, style } = useAspectRatio<HTMLDivElement>(
    { width: aspectWidth, height: aspectHeight },
    { width: aspectWidth, height: aspectHeight }
  );

  const { webpUrl, defaultUrl, defaultImageType } = generateImageUrls(media.url);
  return (
    <Box sx={style} ref={ref}>
      <picture>
        {webpUrl && <source srcSet={webpUrl} type="image/webp" />}
        {defaultUrl && (
          <>
            <source srcSet={defaultUrl} type={defaultImageType} />
            <img src={defaultUrl} alt={media.alternativeText} />
          </>
        )}
      </picture>
    </Box>
  );
};

AspectMedia.displayName = 'AspectMedia';
