import { Box, SxProps } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/immobilien/src/themes/immobilien/main';
import { map } from 'lodash-es';
import React from 'react';
import striptags from 'striptags';

interface LabelValue {
  label: string;
  value: string | number;
}

interface Props {
  data: LabelValue[];
}

const styles: Record<string, SxProps> = {
  boxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    p: { xs: 5, lg: 6 },
    ':first-of-type': { pt: 2 },
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    gap: { xs: 4 },
  },
  typoPrint: {
    '@media print': { fontSize: '13px' },
  },
  button: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
};

export const ContentAttributeTable: React.FunctionComponent<Props> = ({ data }: Props) => (
  <>
    {map(data, (labelValuePair) => (
      <Box key={`${labelValuePair.label}_${labelValuePair.value}`} sx={styles.boxWrapper}>
        <Typography
          className="content-attribute-table-label"
          variant="body2"
          sx={{ ...styles.typoPrint, width: '50%' } as SxProps}
        >
          {labelValuePair.label}
        </Typography>
        <Typography
          className="content-attribute-table-value"
          variant="button"
          fontWeight="bold"
          sx={{ ...styles.typoPrint, ...styles.button, width: '50%' } as SxProps}
          dangerouslySetInnerHTML={{ __html: striptags(`${labelValuePair.value}`, ['a']) }}
        ></Typography>
      </Box>
    ))}
  </>
);

ContentAttributeTable.displayName = 'ContentAttributeTable';
