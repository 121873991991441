import { Button } from '@portals/core/src/components/Button/Button';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import Logger from '../../utils/logger';
export interface Props {
  disabled: boolean;
}

export const HitButton = (props: Props): React.ReactElement => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { values } = useFormikContext<EstateSearchProps>();
  const { t } = useTranslation();
  const { disabled } = props;

  useEffect(() => {
    if (!disabled) {
      fetchHits();
    } else {
      setCount(0);
    }
  }, [values, disabled]);

  const fetchHits = () => {
    setLoading(true);
    ApiClientProvider.getApiClient()
      .getEstateCount(values as EstateSearchProps)
      .then(({ totalItems }) => {
        setCount(totalItems);
      })
      .catch((e) => {
        setCount(0);
        Logger.warn(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      data-testid="estateSearchHitButton"
      loading={loading}
      disabled={disabled}
      variant="standard"
      color="primary"
      type="submit"
      fullWidth
      sx={{ height: { xs: '45px', lg: '62px' } }}
    >
      {t('showResults', { count })}
    </Button>
  );
};

HitButton.displayName = 'HitButton';
