import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useLocalstorageState } from 'rooks';

export type UseEstateSearchLocalStorage = {
  updateLastSearchQuery: (query: EstateSearchProps) => void;
  lastSearchQuery: EstateSearchProps | null;
};

export const useEstateSearchLocalStorage = (): UseEstateSearchLocalStorage => {
  const [lastSearchQuery, setLastSearchQuery] = useLocalstorageState<EstateSearchProps | null>(
    'search_lastSearchQuery',
    null
  );

  const updateLastSearchQuery = (query: EstateSearchProps) => {
    setLastSearchQuery(query);
  };

  return {
    updateLastSearchQuery,
    lastSearchQuery,
  };
};
