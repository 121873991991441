import { MarketingType } from '@portals/sip-client-data/src/general/ApiClientTypes';

export const getAllMarketingTypes = (t) => {
  return [
    {
      value: MarketingType.BUY,
      label: t('marketingType.buy'),
    },
    {
      value: MarketingType.RENT,
      label: t('marketingType.rent'),
    },
  ];
};
