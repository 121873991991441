import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CheckCircleOutline, ChevronDown } from '@portals/icons';
import { find } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { FunctionComponent, useCallback, useState } from 'react';

import { if6CssPrefix } from '../../config/if6CssPrefix';
import { usePreventSafariAutozoom } from '../../hooks/usePreventSafariAutozoom/usePreventSafariAutozoom';

interface Props {
  items: any;
  onChange: (any) => void;
  formatValue: (any) => string;
  label?: string;
  selectProps?: SelectProps;
  customInput?: boolean;
  customInputPlaceholder?: string;
  initialValue?: string | number;
  testId?: string;
  selectStyles?: SxProps;
}

export const CustomSelect: FunctionComponent<Props> = ({
  items,
  onChange,
  formatValue,
  selectProps,
  label,
  customInput,
  customInputPlaceholder,
  initialValue,
  testId,
  selectStyles,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getInitialValueForCustomUserInput = () => {
    const initialValueExistsInDropdown = find(items, ['value', initialValue]);
    if (initialValue && !initialValueExistsInDropdown) {
      return {
        value: initialValue.toString(),
        label: formatValue(initialValue),
      };
    }
    return { value: '', label: '' };
  };

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue ? initialValue : '');
  const [userValue, setUserValue] = useState(getInitialValueForCustomUserInput());

  const preventSafariAutozoom = usePreventSafariAutozoom();

  const handleChange = useCallback(
    (event) => {
      if (event.target.value !== '') {
        setSelectedValue(event.target.value);
        setOpen(false);
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  const handleCustomText = useCallback(
    (event) => {
      setSelectedValue(event.target.value);

      setUserValue({
        value: event.target.value,
        label: formatValue(event.target.value),
      });
    },
    [formatValue]
  );

  const handleKeyDown = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key === 'Enter') {
        setOpen(false);
        onChange(selectedValue);
      }
    },
    [onChange, selectedValue]
  );

  const onClose = (event, reason) => {
    onChange(selectedValue);
    if (reason === 'backdropClick') {
      setOpen(false);
    }
  };

  const renderLabel = () => {
    if (!label) {
      return;
    }
    return { label };
  };

  const checkIfTypeIsOther = useCallback(
    (value) => {
      return value === Number.MAX_VALUE ? t('estateSearch.any') : formatValue(value);
    },
    [formatValue, t]
  );

  const menuListProps = {
    MenuListProps: {
      className: 'estate-custom-select-menu-list',
    },
  };

  return (
    <FormControl fullWidth>
      {label && (
        <InputLabel id={`estate-search-extended-filter-label_${label}`} sx={{ top: '3px' }}>
          {label}
        </InputLabel>
      )}
      <Select
        data-testid={testId}
        className="estate-custom-select"
        variant="standard"
        {...renderLabel()}
        labelId={`estate-search-extended-filter-label_${label}`}
        value={selectedValue}
        IconComponent={ChevronDown}
        onChange={handleChange}
        MenuProps={{
          disableScrollLock: true,
          marginThreshold: null,
          disableAutoFocusItem: true,
          onClose: onClose,
          className: if6CssPrefix,
          ...menuListProps,
        }}
        open={open}
        renderValue={checkIfTypeIsOther}
        /* eslint-disable-next-line react/jsx-no-bind */
        onOpen={() => {
          setOpen(true);
        }}
        {...selectProps}
        sx={{
          fontSize: { xs: 13, lg: 16 },
          '& .MuiSelect-icon': {
            fontSize: 'inherit',
            marginRight: { xs: theme.spacing(4), md: theme.spacing(5) },
            right: 0,
          },
          '&& .MuiSelect-select.MuiInput-input:focus': { background: 'transparent' },
          '::after': {
            borderBottomColor: theme.palette.primary.dark,
          },
          ...selectStyles,
        }}
      >
        {items.map((item) => {
          return (
            <MenuItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingX: {
                  xs: theme.spacing(4),
                  lg: theme.spacing(5),
                },
              }}
              key={item.value}
              value={item.value}
              disableRipple
              disableTouchRipple
            >
              <Typography variant="button">{item.label}</Typography>
              {selectedValue === item.value && <CheckCircleOutline display="inline-flex" />}
            </MenuItem>
          );
        })}
        {customInput && (
          <MenuItem
            sx={{
              display: 'none',
            }}
            value={userValue.value}
          >
            {userValue.label}
          </MenuItem>
        )}
        {customInput && (
          <MenuItem
            value=""
            disableRipple
            disableTouchRipple
            className={userValue.value ? 'menu-item-textfield-highlighted' : 'menu-item-textfield-default'}
            sx={{
              display: 'flex',
            }}
          >
            <TextField
              className="menu-item-textfield"
              fullWidth
              variant="outlined"
              value={userValue.value}
              onChange={handleCustomText}
              onKeyDown={handleKeyDown}
              placeholder={customInputPlaceholder}
              {...preventSafariAutozoom}
            />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

CustomSelect.displayName = 'CustomSelect';
