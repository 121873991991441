import { Box } from '@mui/material';
import { EstateEyecatcher } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { map } from 'lodash-es';
import React from 'react';

import PropertyPill from '../PropertyPill/PropertyPill';

export interface BadgesProps {
  badgeItems: Array<EstateEyecatcher>;
}

export default function Badges({ badgeItems }: BadgesProps): React.ReactElement {
  if (badgeItems?.length === 0) {
    return null;
  }

  return (
    <Box>
      {map(badgeItems, (badgeItem: EstateEyecatcher, key) => {
        return (
          <PropertyPill
            key={`${key}-badge-${badgeItem.type}`}
            size="small"
            label={badgeItem.label}
            isHighlighted={badgeItem.type === 'highlighted'}
          />
        );
      })}
    </Box>
  );
}

Badges.displayName = 'Badges';
