import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';

import { clientSendUserEvent } from '../api/clientSendUserEvent';
import { trackEventMapping } from './trackEventMapping';

type GtmPayload = {
  event: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
};

const getEstateTrackingData = (estate: EstateDetail): Record<string, string | number> => {
  return {
    id: estate.id,
    state: estate.address.city,
    city: estate.address.city,
    zip: estate.address.zip,
    instituteCode: estate.instituteCode,
    propertyType: estate.priceIndicator?.type,
    purchasePrice: estate.priceNumeric,
  };
};

const trackGtm = (eventName: string, trackingData: any): void => {
  const gtmPayload = {
    event: 'event',
  } as GtmPayload;

  const eventConfig = trackEventMapping[eventName];
  if (eventConfig) {
    gtmPayload.eventAction = eventConfig.act;
    gtmPayload.eventCategory = eventConfig.cat;

    if (eventConfig.label) {
      gtmPayload.eventLabel = eventConfig.label;
    } else if (eventConfig.addBlz === true && trackingData.instituteCode) {
      gtmPayload.eventLabel = trackingData.instituteCode;
    }
  } else {
    gtmPayload.eventCategory = 'Sonstiges';
    gtmPayload.eventAction = eventName;
  }

  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(gtmPayload);
};

const trackEvent = (eventName: string, trackingData: any): void => {
  if (typeof window !== 'undefined') {
    if (typeof CustomEvent === 'function') {
      const event = new CustomEvent(eventName, {
        detail: trackingData,
      });
      document.dispatchEvent(event);
    } else {
      const event = document.createEvent('CustomEvent');
      event.initCustomEvent(eventName, true, true, trackingData);
      document.body.dispatchEvent(event);
    }
  }

  trackGtm(eventName, trackingData);

  clientSendUserEvent({
    type: eventName,
    estate_id: trackingData.id,
    extras: trackingData,
  });
};

export const trackEstateView = (estate: EstateDetail): void => {
  trackEvent('expo_views', getEstateTrackingData(estate));
};

export const trackEstatePrint = (estate: EstateDetail): void => {
  trackEvent('expose_download', getEstateTrackingData(estate));
};

export const trackBrokerImprint = (estate: EstateDetail): void => {
  trackEvent('legalnotice_click', getEstateTrackingData(estate));
};

export const trackEstateOpenMap = (estate: EstateDetail): void => {
  trackEvent('map_click', getEstateTrackingData(estate));
};

export const trackBrokerContactOpen = (estate: EstateDetail): void => {
  trackEvent('contactBroker_select', getEstateTrackingData(estate));
};

export const trackBrokerContactSent = (estate: EstateDetail): void => {
  trackEvent('contactBroker_success', getEstateTrackingData(estate));
};

export const trackEstateBookmarkAdd = (estateId: string): void => {
  trackEvent('wishlist_add', {
    id: estateId,
  });
};

export const trackEstateBookmarkRemove = (estateId: string): void => {
  trackEvent('wishlist_remove', {
    id: estateId,
  });
};

export const trackEstateListItem = (estateId: string): void => {
  trackEvent('list_views', {
    id: estateId,
  });
};
