import { Box, Link as MUILink } from '@mui/material';
import { getEstateDetailsPath } from '@portals/sip-client-data/src/if6/IF6Utils';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { AppConfig } from '../../config/AppConfig';
import { isIF6Context } from '../../utils/appContextUtils';

export type EstateCardProps = {
  id: string;
  isLinkEnabled?: boolean;
  isEstateAd?: boolean;
  state?: string;
  ref?: unknown;
  children: React.ReactNode;
};

export const EstateLink: React.FC<EstateCardProps> = React.forwardRef(
  ({ id, isLinkEnabled = true, isEstateAd, state, children }: EstateCardProps, ref) => {
    const { estateDetailsPath } = AppConfig;
    const router = useRouter();
    const url = isIF6Context() ? getEstateDetailsPath(id) : `${estateDetailsPath}${encodeURIComponent(id)}.html`;
    const isPublished = isEstateAd && state === 'published';

    const handleClick = useCallback(
      (e) => {
        if (isIF6Context()) {
          return;
        }

        e.preventDefault();
        if (!isEstateAd && isLinkEnabled) {
          router.push(url);
        } else if (isLinkEnabled || isPublished) {
          window.open(url, '_ blank');
        }
      },
      [isEstateAd, isLinkEnabled, isPublished, router, url]
    );

    const estateLink = isLinkEnabled || isPublished ? url : undefined;

    return (
      <Box data-testid="estate-link" ref={ref} height={1}>
        <MUILink
          href={estateLink}
          target={isIF6Context() ? '_blank' : undefined}
          onClick={handleClick}
          sx={{
            display: 'block',
            height: '100%',
            underline: 'none',
            color: 'inherit',
            cursor: isLinkEnabled || isPublished ? 'pointer' : 'default',
          }}
        >
          {children}
        </MUILink>
      </Box>
    );
  }
);

EstateLink.displayName = 'EstateLink';
