import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: 0,
        p: 0,
      },
    },
    closeButtonContainer: {
      textAlign: 'right',
      position: { lg: 'absolute' },
      right: { lg: 0 },
      top: 0,
      display: { lg: 'flex' },
      alignItems: 'center',
      height: { lg: '100%' },
    },
    closeButton: {
      position: { lg: 'absolute' },
      right: { lg: 0 },
      top: 0,
      display: { lg: 'none' },
      color: 'inherit',
    },
    closeIcon: {
      fontSize: 14,
    },
    closeIconButton: {
      position: { lg: 'absolute' },
      right: { lg: 0 },
      top: 0,
      display: { xs: 'none', lg: 'flex' },
      border: 'none',
      color: 'inherit',
      m: { xs: 8 },
      p: { xs: '15px' },
      '& svg': { width: theme.spacing(6), height: theme.spacing(6) },
    },
    navButton: {
      borderRadius: '100%',
      borderColor: theme.palette.grey['light'],
      color: theme.palette.grey['dark'],
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      width: '42px',
      height: '42px',
      [theme.breakpoints.up('lg')]: {
        width: '54px',
        height: '54px',
      },
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      gap: { xs: 5, lg: 8 },
      pt: { xs: 0, lg: 8 },
      pb: { xs: 7, lg: 8 },
    },
    img: {
      maxWidth: '100%',
      objectFit: 'contain',
      maxHeight: 'calc(100vh - 170px)',
    },
  };
};
