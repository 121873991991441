import { Box } from '@mui/material';
import { Pill } from '@portals/core/src/components/Pill/Pill';
import { theme } from '@portals/core/src/themes/sde/main';
import React, { useCallback } from 'react';

export interface EyeCatcherProps {
  type: string;
  label: string;
}

export const EyeCatcher: React.FC<{ list: EyeCatcherProps[] }> = ({ list }) => {
  const getPillStyle = useCallback((type) => {
    const defaultProps = {
      marginRight: 3,
      cursor: 'inherit',
      '.MuiChip-icon': { color: 'inherit' },
    };
    switch (type) {
      case 'highlighted':
        return {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          ...defaultProps,
        };
      case 'default':
        return {
          backgroundColor: theme.palette.grey[800],
          color: theme.palette.secondary.contrastText,
          ...defaultProps,
        };
      default:
        return defaultProps;
    }
  }, []);

  if (list?.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flexWrap="wrap" rowGap={3} pt={3}>
      {list.map((item, key) => {
        return (
          <Pill
            variant="filled"
            size="small"
            key={key}
            label={item.label}
            sx={getPillStyle(item.type)}
            className="eyecatcher-pill"
          />
        );
      })}
    </Box>
  );
};

EyeCatcher.displayName = 'EyeCatcher';
