import { Box, Stack, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import { InfoCircleOutline } from '@portals/icons/src/InfoCircleOutline/InfoCircleOutline';
import React from 'react';

export type NoResultNotificationProps = {
  mainText: string;
  subText: string;
  buttonLabel?: string;
  buttonAction?: () => void;
  isButtonClicked?: boolean;
};

export const NoResultNotification: React.FC<NoResultNotificationProps> = ({
  mainText,
  subText,
  buttonLabel,
  buttonAction,
  isButtonClicked,
}: NoResultNotificationProps) => {
  const theme = useTheme<Theme>();

  return (
    <Box
      data-testid="no-result-notification"
      sx={{
        textAlign: 'center',
        margin: '0 auto',
        paddingX: { xs: theme.spacing(5), lg: theme.spacing(6) },
        marginY: { xs: theme.spacing(4), lg: theme.spacing(7) },
        maxWidth: { md: '509px', lg: 'none' },
      }}
    >
      <Stack spacing={theme.spacing(8)} sx={{ alignItems: 'center', margin: '0 auto', maxWidth: { lg: '740px' } }}>
        <InfoCircleOutline
          data-testid="info-icon"
          sx={{
            color: theme.palette.secondary.main,
            width: { xs: theme.spacing(6), lg: theme.spacing(9) },
            height: { xs: theme.spacing(6), lg: theme.spacing(9) },
          }}
        />
        <Box>
          <Typography data-testid="main-text" variant="h4" component="p">
            {mainText}
          </Typography>
          <Typography data-testid="sub-text" variant="body2" component="p">
            {subText}
          </Typography>
        </Box>

        {buttonLabel && buttonAction && (
          <Button
            data-testid="cta-button"
            sx={{ width: { xs: '100%', lg: 'initial' } }}
            onClick={buttonAction}
            loading={isButtonClicked}
          >
            {buttonLabel}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

NoResultNotification.displayName = 'NoResultNotification';
