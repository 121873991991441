import { Box, useTheme } from '@mui/material';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { Carousel } from '@portals/core-immobilien/src/components';
import React, { useMemo } from 'react';

import { ActionFavoriteButton } from '../../EstateDetails/ActionButton/ActionFavoriteButton/ActionFavoriteButton';
import { AspectMedia } from '../AspectMedia/AspectMedia';
import { PartnerLogo } from '../PartnerLogo/PartnerLogo';

export const ImageContainer: React.FC<{
  imageList: string[];
  title: string;
  partnerLogo: string;
  estateId: string;
  showBookmarkButton?: boolean;
}> = ({ imageList, partnerLogo, title, estateId, showBookmarkButton = true }) => {
  const imageComponentList = useMemo(() => {
    return imageList.map((image, key) => {
      return <AspectMedia key={key} media={{ alternativeText: title, url: image }} aspectWidth={3} aspectHeight={2} />;
    });
  }, [imageList, title]);

  const theme = useTheme();

  return (
    <Box
      className="estate-list-item-image-container"
      position="relative"
      height={1}
      width={1}
      zIndex="1"
      bgcolor="grey.400"
      marginRight={{ xs: 0, md: 4, lg: 8 }}
      sx={{
        '& img': {
          height: '100%',
          width: '100%',
          display: 'block',
          objectFit: 'cover',
          textAlign: 'center',
          color: '#FFFFFF',
        },
      }}
    >
      <Box
        position="absolute"
        width={1}
        height={1}
        display="flex"
        color="#FFFFFF"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingDots />
      </Box>
      <Carousel sx={{ aspectRatio: '3/2' }}>{imageComponentList}</Carousel>
      {partnerLogo && <PartnerLogo imgSrc={partnerLogo} />}
      {showBookmarkButton && (
        <Box
          position="absolute"
          zIndex={1}
          top={theme.spacing(3)}
          right={theme.spacing(3)}
          borderRadius="50%"
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <ActionFavoriteButton id={estateId} size="small" />
        </Box>
      )}
    </Box>
  );
};

ImageContainer.displayName = 'ImageContainer';
